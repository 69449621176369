export const sendFeature = (feature: string) => {
    // @ts-expect-error Reason: window.ska is not declared in Window type.
    if (window?.ska && feature) {
        // @ts-expect-error Reason: window.ska is not declared in Window type.
        window.ska(function (skalin) {
            skalin.feature({ name: feature })
        })
    }
}

export const sendIdentityToSkalin = (email: string) => {
    // @ts-expect-error Reason: window.ska is not declared in Window type.
    if (window?.ska && email) {
        // @ts-expect-error Reason: window.ska is not declared in Window type.
        window.ska(function (skalin) {
            skalin.identity({ email: email })
        })
    }
}
