import { FC } from 'react'
import { ActionStatus, useRealtime } from './useRealtime'
import { DataType, AverageValues } from './types'
import IndicatorsGrid from './components/IndicatorsGrid/IndicatorsGrid'
import { DATA_TYPE_ICONS_SRC } from './constants'
import ActionStatusSnackbar from './components/ActionStatusSnackbar'
import { Tabs, Tab, Box, Paper } from '@mui/material'
import { useTranslation } from 'react-i18next'
import LoadingSkeleton from './LoadingSkeleton'
import ZonesList from './ZonesList'
import GroupsList from './GroupsList'
import SiteTelecommand from './components/site.telecommand'
import NotificationMenu from './components/notifications.component'
import NoConnectionFallback from './NoConnectionFallback'
import { sendFeature } from '@/utils/skalin'

const formatAverageIndicators = (averageIndicators: AverageValues) =>
    averageIndicators.map(({ type, average }) => ({
        title: type,
        value:
            type === DataType.STATE ? (
                <span>{(average * 100).toFixed(1)}&thinsp;&#37;</span>
            ) : (
                <span> {average.toFixed(1)}&thinsp;&#8451; </span>
            ),
        iconSrc: DATA_TYPE_ICONS_SRC[type],
    }))

interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    )
}

const RealtimePage: FC = () => {
    const { t } = useTranslation('site', { keyPrefix: 'realtime' })
    const realtime = useRealtime()
    const {
        isLoading,
        siteConnectionStatus,
        averageIndicators,
        actionStatus,
        resetActionStatus,
        activeTabIndex,
        setActiveTabIndex,
        notifications,
    } = realtime

    if (siteConnectionStatus === ActionStatus.ERROR) {
        return <NoConnectionFallback />
    }
    if (isLoading) {
        return <LoadingSkeleton />
    }
    sendFeature('realtime')

    return (
        <>
            <IndicatorsGrid indicators={formatAverageIndicators(averageIndicators)} />
            <Box component="main">
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mt: 2,
                        pr: 1,
                        borderBottom: 1,
                        borderColor: 'divider',
                    }}
                >
                    <Tabs value={activeTabIndex} onChange={(_, newValue: number) => setActiveTabIndex(newValue)}>
                        <Tab label={t('tabs.objects')} value={0} />
                        <Tab label={t('tabs.groups')} value={1} />
                        <Tab label={t('tabs.site')} value={2} />
                    </Tabs>
                    <NotificationMenu notifications={notifications} />
                </Box>
                <Box sx={{ pt: 2 }}>
                    <TabPanel value={activeTabIndex} index={0}>
                        <ZonesList {...realtime} />
                    </TabPanel>
                    <TabPanel value={activeTabIndex} index={1}>
                        <GroupsList {...realtime} />
                    </TabPanel>
                    <TabPanel value={activeTabIndex} index={2}>
                        <Paper sx={{ p: 4 }}>
                            <SiteTelecommand {...realtime} style={{}} />
                        </Paper>
                    </TabPanel>
                </Box>
            </Box>
            <ActionStatusSnackbar status={actionStatus} onClose={resetActionStatus} />
        </>
    )
}

export default RealtimePage
